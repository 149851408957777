<div class="document">
  <h2 class="document-headline">
    {{ 'DOCUMENT_TYPE.' + documentType | translate }}
  </h2>
  <p class="document-description">
    <img
      class="document-icon"
      src="/assets/icons/file.svg"
      [alt]="'REQUEST_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate" />
    {{ name }}
  </p>
</div>
