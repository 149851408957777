import { Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';
import { PdfComponent } from './requests/request-details/components/pdf/pdf.component';
import { connectionRequestResolver } from './requests/request-details/connection-request.resolver';
import { DocumentsComponent } from './requests/request-details/components/documents/documents.component';
import { addonCheckGuardFn } from '@app/shared';

type BasicRoutes = 'dashboard' | '404';
type RequestRoutes = 'requests' | 'requests/:id/pdf';
type SettingsRoutes = 'settings';
type InfoRoutes = 'information';
export type AppRoutes =
  | BasicRoutes
  | RequestRoutes
  | SettingsRoutes
  | InfoRoutes;

export const routes: Routes = [
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        mod => mod.DashboardComponent
      ),
    title: 'Dashboard',
    canActivate: [authGuardFn, addonCheckGuardFn],
  },
  {
    path: 'requests',
    loadComponent: () =>
      import('./requests/requests.component').then(
        mod => mod.RequestsComponent
      ),
    title: 'Requests',
    canActivate: [authGuardFn, addonCheckGuardFn],
  },
  {
    path: 'error',
    loadComponent: () =>
      import('./global-error/global-error.component').then(
        mod => mod.GlobalErrorComponent
      ),
    title: 'Error',
    canActivate: [authGuardFn],
  },
  {
    path: '404',
    loadComponent: () =>
      import('./not-found-page/not-found-page.component').then(
        mod => mod.NotFoundPageComponent
      ),
    title: '404',
    canActivate: [authGuardFn, addonCheckGuardFn],
  },
  {
    path: 'requests/:id',
    loadComponent: () =>
      import('./requests/request-details/request-details.component').then(
        mod => mod.RequestDetailsComponent
      ),
    title: 'Request Details',
    canActivate: [authGuardFn, addonCheckGuardFn],
    resolve: { connectionRequest: connectionRequestResolver },
    children: [
      {
        path: 'pdf',
        component: PdfComponent,
      },
      {
        path: 'documents',
        component: DocumentsComponent,
      },
    ],
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./settings/settings.component').then(
        mod => mod.SettingsComponent
      ),
    title: 'Settings',
    canActivate: [authGuardFn, addonCheckGuardFn],
  },
  { path: '**', redirectTo: 'dashboard' },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
