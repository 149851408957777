<!-- div currently required for grid layout -->
<div>
  @if (isAuthenticated$ | async) {
    <div class="navigation-bar">
      <dso-navigation [navigationItems]="navigationItems" />
    </div>
    @if (isLoading()) {
      <mat-progress-bar mode="indeterminate" class="progress-bar" />
    }
  }
  @if (showSideNav()) {
    <one-side-navigation
      class="side-navigation"
      (expandedChange)="onExpandedChange($event)">
      @for (navigationLink of sideNavigationLinks; track navigationLink.route) {
        <one-side-navigation-element
          [isTextDisplayed]="isSideNavigationExpanded()"
          [icon]="navigationLink.icon"
          [route]="navigationLink.route"
          [isDisabled]="navigationLink.isDisabled">
          {{ navigationLink.translationKey | translate }}
        </one-side-navigation-element>
      }
    </one-side-navigation>
  }
</div>

<div class="container">
  <section class="content">
    <router-outlet />
  </section>
</div>

<one-footer [language]="currentLanguage" />
