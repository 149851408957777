import {
  DestroyRef,
  Directive,
  inject,
  Input,
  HostListener,
} from '@angular/core';
import { ConnectionRequestDto } from 'src/api/dso-portal/generated/models';
import { DownloadUrlCreator } from './download-url-creator';
import { ConnectionRequestsService } from 'src/api/dso-portal/generated/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorHandlingService } from '@app/core/services';

@Directive({
  selector: '[dsoDownloadRequest]',
  standalone: true,
})
export class DownloadRequestDirective {
  @Input({ required: true }) request?: ConnectionRequestDto;
  @Input({ required: true }) type?: 'ZIP' | 'PDF';

  readonly #connectionRequestService = inject(ConnectionRequestsService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #errorHandlingService = inject(ErrorHandlingService);

  @HostListener('click')
  executeDownload(): void {
    if (this.request && this.type) {
      this.#connectionRequestService
        .downloadConnectionRequest({
          id: this.request.id!,
          type: this.type,
        })
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe({
          next: zip =>
            DownloadUrlCreator.create(
              zip as Blob,
              this.request!.requestId,
              this.type
            ),
          error: error => {
            this.#errorHandlingService.handle(error);
          },
        });
    } else {
      this.#errorHandlingService.handle(new Error('No request present'));
    }
  }
}
