import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentDto } from 'src/api/dso-portal/generated/models';

export type DokumentType = DocumentDto['type'];
@Component({
  selector: 'dso-document',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './document.component.html',
  styleUrl: './document.component.scss',
})
export class DocumentComponent {
  @Input({ required: true }) documentType!: DokumentType;
  @Input({ required: true }) name!: string;
}
