import { Icon } from '@eon-one/one-ui/lib/modules/side-navigation/components/side-navigation-element/icons';
import { AppRoutes } from './app.routes';

export interface SideNavigationLink {
  icon: Icon;
  route: AppRoutes;
  translationKey: string;
  isDisabled?: boolean;
}
export const sidenavigationLinks: SideNavigationLink[] = [
  {
    icon: 'dashboard',
    route: 'dashboard',
    translationKey: 'COMMON.DASHBOARD',
  },
  { icon: 'request', route: 'requests', translationKey: 'COMMON.REQUESTS' },
  {
    icon: 'info',
    route: 'information',
    translationKey: 'COMMON.INFORMATION',
    isDisabled: true,
  },
  {
    icon: 'settings',
    route: 'settings',
    translationKey: 'COMMON.SETTINGS',
  },
];
