@if (pdfSrc(); as pdfSource) {
  <div class="pdf-viewer-wrapper" [style.height]="calculatedPdfViewerHeight()">
    <pdf-viewer
      class="pdf-viewer"
      [ngClass]="{ 'h-100': isPdfViewerRendered() }"
      [src]="pdfSource"
      [rotation]="0"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      (page-rendered)="pageRendered()" />
  </div>
  <dso-icon-button
    class="download-button dso-card"
    icon="download"
    dsoDownloadRequest
    [request]="currentRequest()"
    type="PDF">
    {{ 'REQUEST_DETAILS.DOWNLOAD_PDF' | translate }}
  </dso-icon-button>
}
